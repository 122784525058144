.cabinetNotification {
  margin-bottom: 85px;
}

.cabinetNotificationItem {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 90px;
  align-items: flex-start;
  padding: 95px 70px;
  background-color: #2d2f3a;
  border-radius: 6px;
  transition: all 0.4s ease;

  @media (--wide-tablet) {
    flex-direction: column;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    background-color: #2d2f3a;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &:hover {
    background-color: #2d2f3a;
  }

  & time {
    margin-bottom: 9px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    color: #9cb7ef;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  & h2 {
    font-family: "Exo 2", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    color: #f8f8f9;
    letter-spacing: -0.02em;
  }

  & p {
    margin-bottom: 26px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    color: #f8f8f9;
  }

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #9cb7ef;
    text-decoration: none;
    letter-spacing: -0.01em;

    & svg {
      width: 12px;
      height: 11px;
      margin-right: 12px;
    }
  }
}

.cabinetNotificationItemLeft,
.cabinetNotificationItemRight {
  flex: 1 1 50%;
}
