.aboutCompetencies {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 135px;
  margin-bottom: 125px;

  @media (--wide-tablet) {
    column-gap: 40px;
    margin-bottom: 70px;
  }

  @media (--wide-mobile) {
    margin-bottom: 20px;
  }
}

.aboutCompetence {
  display: flex;
  flex: 1 1 calc((100% / 2) - 135px);
  flex-direction: row;
  align-items: center;
  padding: 40px 30px;
  border-bottom: 1px solid #393939;

  &:last-child {
    border-bottom-color: transparent;
  }

  @media (--tablet) {
    flex: 1 1 100%;
  }

  @media (--wide-mobile) {
    padding: 30px 20px;
  }
}

.aboutCompetenceContent {
  @media (--small-desktop) {
    line-height: 22px;
  }

  @media (--wide-mobile) {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.01em;
  }
}

.aboutCompetenceIcon {
  margin-right: 50px;

  @media (--tablet) {
    margin-right: 28px;

    & img {
      width: 42px;
    }
  }
}
