
.pageFeatureItems {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 50px;
  align-items: stretch;

  @media (--big-desktop) {
    /* grid-template-columns: repeat(4, 1fr); */
    grid-gap: 30px;
  }
}

.pageFeatureItem {
  display: flex;
  align-items: flex-end;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.97);
  letter-spacing: -0.01em;
  border: 1px solid #5b616b;
  border-radius: 6px;
  aspect-ratio: 1 / 1;

  @media (--desktop) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
