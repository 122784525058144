.footer {
  display: flex;
  flex-direction: row;
  gap: 150px;
  align-items: flex-start;
  width: 100%;
  max-width: 1920px;
  padding: 120px var(--global-padding);
  margin: 0 auto;
  border-top: 1px solid #2e313f;

  @media (--small-desktop) {
    grid-gap: 25px;
    gap: 25px;
    max-width: 1280px;
    padding: 60px var(--global-padding);
  }

  @media (--wide-tablet) {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 35px var(--global-padding);
  }
}

.footerLeft {
  @media (--wide-tablet) {
    width: 100%;
  }
}

.footerRight {
  @media (--wide-tablet) {
    width: 100%;
  }
}

.footerLogo {
  @media (--wide-tablet) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
}

.footerCopyright {
  margin-bottom: 29px;
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  color: #dadada;
  letter-spacing: 0.05em;

  @media (--tablet) {
    margin-bottom: 14px;
  }
}

.footerLicense {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 37px;

  & a {
    display: inline-flex;
    font-family: "Exo 2", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 162%;
    color: rgb(218 218 218 / 0.5);
    text-decoration: none;
    letter-spacing: 0.01em;
    white-space: nowrap;
    border-bottom: 1px solid #85a0d8;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
    }
  }

  @media (--tablet) {
    margin-bottom: 6px;
  }
}

.footerMiddle {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;

  @media (--wide-tablet) {
    display: none;
  }
}

.footerMenu {
  display: flex;
  flex-direction: row;
  gap: 90px;
  align-items: flex-start;
  justify-content: flex-end;

  & a {
    margin-bottom: 17px;
    font-family: "Exo 2", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    color: #85a0d8;
    text-decoration: none;
    letter-spacing: 0.01em;
    white-space: nowrap;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
    }
  }

  @media (--small-desktop) {
    grid-gap: 20px;
    gap: 20px;
    min-width: 500px;
  }
}

.footerMenuColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (--small-desktop) {
    margin: 0 20px;
  }
}

.footerRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.footerSocials {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 110px;

  @media (--wide-tablet) {
    margin-bottom: 14px;
  }
}

.footerSocial {
  margin-left: 35px;

  & svg {
    width: 24px;
    height: 24px;
    fill: #85a0d8;
    transition: all 0.3s ease;
  }

  &:hover {
    & svg {
      fill: #a5bff3;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  @media (--small-desktop) {
    margin-left: 15px;
  }
}

.footerDanke {
  font-family: "Exo 2", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  text-align: right;
  letter-spacing: 0.01em;

  & a {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #85a0d8;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
    }
  }
}
