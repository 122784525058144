.productHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 100%;
  height: 445px;

  @media (--tablet) {
    height: 300px;
  }
}

.productHeaderBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.productHeaderContent {
  position: relative;
  z-index: 10;
  max-width: var(--global-width);
  padding-right: var(--global-padding);
  padding-bottom: 40px;
  padding-left: var(--global-padding);

  & h1 {
    margin: 0;
    font-family: "Exo 2", sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
    color: rgb(255 255 255 / 0.97);
    letter-spacing: -0.03em;
  }

  & p {
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    color: #9cb7ef;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (--tablet) {
      margin-bottom: 10px;
    }
  }
}
