.pagePictureFull {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.pagePicture {
  width: 100%;

  & img {
    max-width: 100%;
    object-fit: cover;

    @media (--tablet) {
      width: 100%;
    }
  }
}

.pagePictureRight {
  align-self: flex-end;
}

.pagePictureTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (--mobile) {
    grid-template-columns: 1fr;
  }
}

.pagePictureMargin {
  margin-bottom: 30px;
}
