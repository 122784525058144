.mainFactsWrap {
  padding: 70px 0 150px;
  background-color: #2d2f3a;

  @media (--small-desktop) {
    padding: 70px 0 90px;
  }
}

.mainFacts {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
  display: grid;

  /* align-items: flex-start; */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 130px;

  @media (--small-desktop) {
    gap: 60px;
  }

  @media (--wide-tablet) {
    gap: 30px;
  }

  @media (--tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px 20px;
  }
}

.mainFact {
  /* flex: 1 1 calc((100% / 4) - 130px);

  @media (--small-desktop) {
    flex-basis: calc(25% - 30px);
    padding-right: 30px;
  } */

  & span {
    display: block;
    margin-bottom: 32px;
    font-family: "Exo 2", sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: normal;
    line-height: 96px;
    color: #85a0d8;
    letter-spacing: -0.307692px;

    @media (--small-desktop) {
      font-size: 60px;
      line-height: 68px;
    }

    @media (--wide-tablet) {
      font-size: 40px;
      line-height: 48px;
    }

    @media (--wide-mobile) {
      margin-bottom: 16px;
      font-size: 36px;
      line-height: 43px;
      letter-spacing: -0.307692px;
    }
  }

  & p {
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
    letter-spacing: -0.0923077px;

    @media (--small-desktop) {
      font-size: 16px;
      line-height: 22px;
    }

    @media (--small-desktop) {
      font-size: 14px;
      line-height: 20px;
    }

    @media (--wide-mobile) {
      font-size: 13px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: -0.0923077px;
    }
  }
}
