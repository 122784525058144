.cabinetControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 33px 62px;
  background: linear-gradient(90deg, #2e313f 100px, #2a2d39 100px);
  border-radius: 30px;

  @media (--tablet) {
    margin-bottom: 20px;
  }

  @media (--wide-mobile) {
    flex-wrap: wrap;
    padding: 25px 20px;

    /* flex-direction: column;
    align-items: flex-start; */
    background: linear-gradient(90deg, #2e313f 90px, #2a2d39 90px);
  }
}

.cabinetControlAvatar {
  margin-right: 35px;

  @media (--wide-mobile) {
    margin-right: 25px;
  }

  & img {
    width: 75px;
    height: 75px;

    @media (--wide-mobile) {
      width: 50px;
      height: 50px;
    }
  }
}

.cabinetControlName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 75px;

  & p {
    font-family: "Exo 2", sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: #fff;
    letter-spacing: -0.01em;

    @media (--wide-mobile) {
      margin-bottom: 0 !important;
    }
  }

  & a {
    font-family: "Exo 2", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    color: #85a0d8;
    letter-spacing: -0.02em;
  }

  @media (--wide-mobile) {
    padding-left: 20px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.cabinetControlButtons {
  align-self: flex-start;

  @media (--wide-mobile) {
    width: 100%;
    padding-left: 90px;
  }
}
