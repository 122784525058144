.buttonSmall {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 23px;
  color: #000;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.75px;
  background: #85a0d8;
  border: 1px solid #85a0d8;
  border-radius: 30px;
}
