.pageRow {
  display: flex;
  flex-direction: row;
}

.pageRowGapBig {
  gap: 150px;

  @media (--wide-tablet) {
    gap: 70px;
  }

  @media (--tablet) {
    flex-direction: column;
    gap: 30px;
  }
}

.pageRowGapMedium {
  gap: 120px;

  @media (--wide-tablet) {
    gap: 60px;
  }
}

.pageColumnHalf {
  display: flex;
  flex: 0 1 50%;
  flex-direction: column;
}

.pageColumnBig {
  flex: 0 1 66.6%;

  @media (--small-desktop) {
    padding-right: 50px;
  }

  @media (--tablet) {
    flex: 0 1 100%;
  }
}

.pageColumnSmall {
  flex: 0 1 33.3%;

  @media (--tablet) {
    flex: 0 1 100%;
  }
}

.pageColumnThree {
  display: flex;
  flex: 0 1 calc(33.3% - 120px);
  flex-direction: column;
}

.pageGridTwo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;

  @media (--wide-tablet) {
    gap: 60px;
  }

  @media (--tablet) {
    grid-template-columns: 1fr;
    gap: 35px;
  }
}

.pageGridThree {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 120px;

  @media (--wide-tablet) {
    gap: 60px;
  }

  @media (--tablet) {
    gap: 30px;
  }

  @media (--wide-mobile) {
    grid-template-columns: 1fr;
    gap: 25px;
  }
}

.pageGridItem {
  display: flex;
  flex-direction: column;
}

.pageGridItemSpace {
  justify-content: space-between;
}
