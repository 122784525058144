.buttonOutline {
  --color: #fff;

  display: flex;
  align-items: center;
  height: 45px;
  padding: 0 26px;
  margin-right: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  color: var(--color);
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid var(--color);
  border-radius: 6px;

  &:last-child {
    margin-right: 0;
  }
}
