.mobileMenu {
  position: fixed;
  top: 64px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
  padding: 15px 0 0;
  background-color: #9cb7ef;
  transition: all 0.3s ease-out;
  transform: translateX(150%);
}

.mobileMenuShow {
  transform: translateX(0);
}

.mobileMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 17px 25px;
  font-family: "Exo 2", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #2c2f3c;
  text-decoration: none;
  letter-spacing: -0.1px;
  border-bottom: 1px solid rgb(51 51 51 / 0.1);

  & svg * {
    transition: all 0.3s ease-out;
    transform-origin: 50%;
  }
}

.mobileMenuExpandTriggerOpen {
  & svg path {
    transform: rotate(180deg);
  }
}

.mobileMenuExpand {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease-out;

  & a {
    padding: 17px 25px 17px 35px;
  }
}

.mobileMenuExpandOpen {
  max-height: 999px;
}
