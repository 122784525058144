.basic {
  & h1 {
    margin: 0;
    margin-bottom: 76px;
    font-family: "Exo 2", sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 61px;
    color: #f8f8f9;
    letter-spacing: -0.03em;

    @media (--small-desktop) {
      margin: 0 0 50px;
      font-size: 46px;
      line-height: 52px;
    }

    @media (--wide-mobile) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  & h2 {
    margin-bottom: 60px;
    font-family: "Exo 2", sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: rgb(255 255 255 / 0.97);
    letter-spacing: -0.01em;

    @media (--wide-mobile) {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 19px;
    }
  }

  & .h2SmallBottomMargin {
    margin-bottom: 15px;
  }

  & time {
    display: block;
    margin-bottom: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #9cb7ef;
    letter-spacing: -0.01em;
  }

  & p {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    color: #e6e8eb;
    letter-spacing: -0.005em;

    & img {
      width: 80% !important;
      max-width: 1200px;
    }

    & iframe {
      width: 100%;
      height: 400px;
      height: auto;
      aspect-ratio: 16 / 9;
    }

    & em {
      font-size: 14px;
      font-weight: bold;
    }
  }

  & a {
    color: #9cb7ef;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    transition: all 0.3s;

    &:hover {
      border-bottom: none;
    }
  }

  & .accent {
    max-width: 600px;
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #9cb7ef;
    letter-spacing: -0.01em;
  }
}
