.hamburger {
  display: inline-block;
  padding: 15px;
  margin: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}

.hamburger-box {
  position: relative;

  /* display: inline-block; */
  width: 24px;
  height: 18px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  position: absolute;
  width: 24px;
  height: 2px;
  background-color: #fff;
  border-radius: 2px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}

.hamburger-inner::before,
.hamburger-inner::after {
  display: block;
  content: "";
}

.hamburger-inner::before {
  top: -7px;
}

.hamburger-inner::after {
  bottom: -7px;
}

.hamburger--stand .hamburger-inner {
  transition:
    transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
    background-color 0s 0.075s linear;
}

.hamburger--stand .hamburger-inner::before {
  transition:
    top 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand .hamburger-inner::after {
  transition:
    bottom 0.075s 0.075s ease-in,
    transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--stand.is-active .hamburger-inner {
  background-color: transparent !important;
  transition:
    transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
    background-color 0s 0.15s linear;
  transform: rotate(90deg);
}

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transition:
    top 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transition:
    bottom 0.075s 0.1s ease-out,
    transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
