.login {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #2e313f;
  border-radius: 30px;

  @media (--wide-tablet) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.loginLeft {
  flex: 1 1 50%;
  padding: 57px var(--global-padding);
  font-family: "Exo 2", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 46px;
  color: rgb(255 255 255 / 0.97);
  letter-spacing: -0.02em;

  @media (--wide-tablet) {
    flex: 0 1 auto;
    padding: 30px var(--global-padding);
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.02em;
  }
}

.loginRight {
  flex: 1 1 50%;
  padding: 125px var(--global-padding);
  background: rgb(33 35 44 / 0.2);

  @media (--wide-tablet) {
    flex: 0 1 auto;
    padding: 30px var(--global-padding);
  }
}

.loginFormItem {
  margin-bottom: 25px;
}

.loginFormSubmit {
  display: flex;
  flex-direction: row;
  align-items: middle;
  justify-content: space-between;
}
