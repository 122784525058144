.newsList {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 57px;
}

.newsListItem {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 90px;
  align-items: flex-start;
  padding: 95px 70px;
  border-radius: 6px;
  transition: all 0.4s ease;

  @media (--small-desktop) {
    gap: 45px;
    padding: 45px 50px;
  }

  @media (--tablet) {
    gap: 25px;
    padding: 35px 20px;
  }

  @media (--wide-mobile) {
    flex-direction: column;
    padding: 35px 0;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    background-color: #2d2f3a;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &:hover {
    background-color: #2d2f3a;
  }

  & time {
    margin-bottom: 9px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    color: #9cb7ef;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media (--wide-mobile) {
      margin-bottom: 14px;
      font-size: 10px;
      line-height: 22px;
      letter-spacing: 0.1em;
    }
  }

  & h2 {
    font-family: "Exo 2", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    color: #f8f8f9;
    letter-spacing: -0.02em;

    @media (--small-desktop) {
      font-size: 28px;
      line-height: 32px;
    }

    @media (--wide-mobile) {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
    }
  }

  & p {
    margin-bottom: 26px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    color: #f8f8f9;

    @media (--wide-mobile) {
      font-size: 14px;
      line-height: 23px;
      letter-spacing: -0.005em;
    }
  }

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #9cb7ef;
    text-decoration: none;
    letter-spacing: -0.01em;

    & svg {
      width: 12px;
      height: 11px;
      margin-right: 12px;
    }
  }
}

.newsListItemTitle {
  flex: 0 1 50%;
}

.newsListItemDetail {
  flex: 0 1 50%;
  padding-top: 31px;

  @media (--wide-mobile) {
    flex: 0 1 auto;
    padding-top: 0;
  }
}

.newsMore {
  margin-bottom: 100px;

  & .newsList {
    margin-bottom: 0;
  }

  & .newsListItem {
    &::after {
      background-color: #21232c;
    }
  }
}

.newsListMore {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (--small-desktop) {
    margin-bottom: 60px;
  }
}
