.productContent {
  padding-top: 100px;
  padding-bottom: 100px;

  @media (--small-desktop) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @media (--tablet) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
