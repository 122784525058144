.cabinetFiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px 50px;
  margin-bottom: 90px;

  @media (--tablet) {
    flex-direction: column;
  }

  @media (--wide-mobile) {
    margin-bottom: 40px;
  }
}

.cabinetFile {
  --color: #fff;

  display: flex;
  flex: 1 1 calc((100% / 2) - 50px);
  flex-direction: row;
  align-items: center;
  max-width: calc((100% / 2) - 50px);
  padding: 15px 25px 15px 40px;
  background: linear-gradient(90deg, #2e313f 70px, #2a2d39 70px);
  border-radius: 10px;

  @media (--tablet) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  @media (--wide-mobile) {
    flex-wrap: wrap;
    background: linear-gradient(180deg, #2e313f 0, #2a2d39 0);
  }
}

.cabinetFileIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 25px;

  & svg {
    width: 60px;
    height: 60px;
  }
}

.cabinetFileName {
  flex: 1 1 auto;

  @media (--wide-mobile) {
    width: 100%;
  }

  & span {
    font-family: "Exo 2", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: var(--color);
    letter-spacing: -0.02em;
  }

  & p {
    font-family: "Exo 2", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    letter-spacing: -0.01em;
  }
}

.cabinetFileDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  justify-content: space-between;
  height: 100%;

  @media (--wide-mobile) {
    justify-content: flex-start;
    width: 100%;
  }
}

.cabinetFileSize {
  font-family: "Exo 2", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: #d3d3d3;
  text-align: right;
  letter-spacing: -0.02em;

  @media (--wide-mobile) {
    text-align: left;
  }
}

.cabinetFileComment {
  font-family: "Exo 2", sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 9px;
  color: #d8d8d8;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 0.15em;

  @media (--wide-mobile) {
    text-align: left;
  }
}

.cabinetFileMTS {
  --color: #2f80ed;
}

.cabinetFileManus {
  --color: #9b51e0;
}

.cabinetFileSolidcam {
  --color: #eb5757;
}
