.header {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background-color: #21232c;
  border-bottom: 1px solid #464c57;
  transition: all 0.3s;
}

.headerTransparent {
  & .header {
    background-color: transparent;
    border-bottom: 1px solid #464c57;
  }
}

.headerTransparentImportant {
  background-color: #21232c !important;
  border-bottom: 1px solid transparent !important;
}

.headerControl {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.headerLogo {
  padding-left: 45px;

  @media (--wide-mobile) {
    padding-left: 6vw;

    & img {
      width: 150px;
    }
  }
}

.headerMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-right: 80px;

  & a,
  .headerSubmenuTrigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 3px 20px 0;
    font-family: "Exo 2", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    color: #acb1b6;
    text-align: center;
    text-decoration: none;
    letter-spacing: -0.1px;
    white-space: nowrap;
    border-bottom: 3px solid transparent;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }

    @media (--small-desktop) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  & .headerMenuActive {
    border-bottom: 3px solid #85a0d8;
  }

  @media (max-width: 1220px) {
    display: none;
  }
}

.headerSubmenuTrigger {
  cursor: pointer;

  &:hover {
    .headerSubmenu {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.headerSubmenu {
  position: absolute;
  top: 65px;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 65px;
  text-decoration: none;
  pointer-events: none;
  background-color: #4d5a78;
  opacity: 0;
  transition: all 0.4s ease-in-out;

  @media (max-width: 1220px) {
    display: none;
  }
}

.headerLogin {
  height: 100%;
  border-left: 1px solid #464c57;

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0 45px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1220px) {
    display: none;
  }
}

.headerPhone {
  height: 100%;
  background-color: rgb(156 183 239 / 0.44);

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0 45px;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 0.03em;

    & svg {
      width: 20px;
      height: 20px;
      margin-right: 16px;

      @media (--tablet) {
        margin-right: 0;
      }
    }

    @media (--small-desktop) {
      min-width: 260px;
      font-size: 16px;
    }

    @media (--tablet) {
      justify-content: center;
      min-width: 0;
      padding: 0 17px;
      font-size: 0;

      & svg {
        margin-right: 0;
      }
    }
  }

  @media (--tablet) {
    justify-content: center;
    min-width: 80px;
  }

  @media (--small-mobile) {
    min-width: 0;
  }
}

.headerMobileMenu {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    min-width: 80px;
  }

  @media (--small-mobile) {
    min-width: 0;
  }
}

.headerMobileMenuShow {
  background-color: rgb(156 183 239 / 0.44);
}
