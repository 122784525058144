.contact {
  position: relative;
}

.contactContentHeader {
  @media (--wide-tablet) {
    display: none;
  }
}

.contactContentMobileHeader {
  display: none;

  @media (--wide-tablet) {
    position: absolute;
    top: 130px;
    left: var(--global-padding);
    z-index: 50;
    display: block;
  }

  @media (--wide-mobile) {
    top: 100px;
  }
}

.contactContent {
  position: relative;
  z-index: 10;
  min-height: 100vh;
  padding-top: 145px;
  padding-bottom: 117px;
  pointer-events: none;

  @media (--wide-tablet) {
    min-height: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (--tablet) {
    padding: 0 !important;
  }
}

.contactContentBlock {
  width: 510px;
  padding: 14px 43px 0;
  pointer-events: all;
  background: #2d2f3a;
  border-radius: 6px;

  & p {
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #dadada;
    letter-spacing: -0.1px;

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & a {
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #85a0d8;
    letter-spacing: -0.1px;

    @media (--tablet) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  @media (--tablet) {
    width: 100%;
    padding: 0 var(--global-padding);
  }
}

.contactContentRow {
  padding: 27px 0 41px;
  border-bottom: 1px solid #25252c;
}

.contactContentColums {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  grid-gap: 40px;
  padding: 27px 0 41px;
  border-bottom: 1px solid #25252c;

  @media (--tablet) {
    grid-gap: 20px;
  }
}

.contactContentSocials {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.contactContentSocial {
  padding-top: 17px;
  margin-right: 35px;

  & svg {
    width: 24px;
    height: 24px;
    fill: #85a0d8;
    transition: all 0.3s ease;
  }

  &:hover {
    & svg {
      fill: #a5bff3;
    }
  }
}
