.page {
  flex: 1 1 auto;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;

  & p {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    color: #e6e8eb;
    letter-spacing: -0.005em;

    @media (--small-desktop) {
      font-size: 18px;
      line-height: 28px;
    }

    @media (--tablet) {
      margin-bottom: 26px;
      font-size: 16px;
      line-height: 26px;
    }

    @media (--wide-mobile) {
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 24px;
    }
  }

  & .big {
    margin-bottom: 32px;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 36px;
    color: #e6e8eb;
    letter-spacing: -0.005em;

    @media (--small-desktop) {
      font-size: 22px;
      line-height: 32px;
    }

    @media (--tablet) {
      font-size: 18px;
      line-height: 28px;
    }

    @media (--wide-mobile) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  & .accent {
    max-width: 600px;
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #9cb7ef;
    letter-spacing: -0.01em;

    @media (--tablet) {
      font-size: 16px;
      line-height: 23px;
    }

    @media (--wide-mobile) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  ul {
    padding-left: 28px;

    & li {
      font-family: "Montserrat", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      color: #e6e8eb;
      letter-spacing: -0.005em;

      @media (--small-desktop) {
        font-size: 17px;
        line-height: 26px;
      }

      @media (--wide-mobile) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  @media (--small-desktop) {
    max-width: 1280px;
  }
}

.pagePadding {
  padding: 145px 0 65px;

  @media (--tablet) {
    padding: 80px 0 65px;
  }

  @media (--wide-mobile) {
    padding: 95px 0 65px;
  }
}

.pageWrap {
  max-width: var(--global-width);
  padding-right: var(--global-padding);
  padding-left: var(--global-padding);
}

.pageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (--tablet) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  & h1 {
    margin: 0;
    margin-bottom: 90px;
    font-family: "Exo 2", sans-serif;
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: 90px;
    color: #f8f8f9;
    letter-spacing: -0.03em;

    @media (--small-desktop) {
      margin: 0 0 60px;
      font-size: 40px;
      line-height: 48px;
    }

    @media (--tablet) {
      margin: 0 0 40px;
      font-size: 36px;
      line-height: 44px;
    }

    @media (--wide-mobile) {
      margin: 0 0 30px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  & time {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 22px;
    color: #9cb7ef;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
}

.pageHeaderAlignTop {
  align-items: flex-start;
}

.pageHeaderButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pageSection {
  margin-bottom: 155px;

  @media (--tablet) {
    margin-bottom: 60px;
  }

  @media (--wide-mobile) {
    margin-bottom: 25px;
  }
}

.pageSectionHeader {
  & h2 {
    font-family: "Exo 2", sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: #85a0d8;
    letter-spacing: -0.01em;

    @media (--small-desktop) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (--wide-mobile) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  & h3 {
    margin-bottom: 5px;
    font-family: "Exo 2", sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: rgb(255 255 255 / 0.97);
    letter-spacing: -0.01em;

    @media (--small-desktop) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (--tablet) {
      font-size: 20px;
      line-height: 24px;
    }

    @media (--wide-mobile) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  & p {
    font-family: "Exo 2", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    color: #85a0d8;
    letter-spacing: -0.01em;

    @media (--tablet) {
      font-size: 16px;
      line-height: 20px;
    }

    @media (--wide-mobile) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.pageSectionHeaderMargin {
  margin-bottom: 115px;

  @media (--tablet) {
    margin-bottom: 40px;
  }

  @media (--wide-mobile) {
    margin-bottom: 25px;
  }
}

.pageCenterAccent {
  position: relative;
  padding-top: 40px;
  font-family: "Exo 2", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 46px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.02em;

  @media (--tablet) {
    padding-top: 30px;
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 30px;
  }

  @media (--wide-mobile) {
    padding-top: 20px;
    font-size: 16px;
    line-height: 26px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    width: 115px;
    height: 2px;
    content: "";
    background-color: #9cb7ef;
    transform: translate(-50%, 0);
  }
}
