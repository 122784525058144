.aboutLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 35px 25px;
  margin-bottom: 125px;

  @media (--tablet) {
    gap: 3px 20px;
    margin-bottom: 70px;
  }

  @media (--wide-mobile) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px 15px;
    margin-bottom: 20px;
  }
}

.aboutLogo {
  box-sizing: border-box;
  display: flex;
  flex: 1 1 calc((100% / 5) - 25px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  aspect-ratio: 3 / 2;
  border: 1px solid #2c2c2c;
  border-radius: 5px;

  @media (--small-desktop) {
    padding: 15px;

    /* margin: 15px; */

    & img {
      width: 100px;
    }
  }

  @media (--wide-mobile) {
    flex: 1 1 calc((100% / 8) - 25px);
    height: 100%;
    aspect-ratio: unset;
    margin: 0;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
