.notFound {
  height: 100%;
  padding: 150px 100px;
  background: #2e313f;
  background-image: url("/img/404.svg");
  background-repeat: no-repeat;
  background-position: center right 85px;
  background-size: auto 80%;
  border-radius: 30px;

  & p {
    max-width: 800px;
    margin-bottom: 50px;

    @media (--small-desktop) {
      max-width: 600px;
    }
  }
}
