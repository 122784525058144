.newsFeatures {
  display: flex;
  flex-direction: row;
  gap: 48px;
  margin-bottom: 105px;
}

.newsFeature {
  display: flex;
  flex: 0 1 20%;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 18px 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.97);
  letter-spacing: -0.01em;
  border: 1px solid #5b616b;
  border-radius: 6px;
  aspect-ratio: 1 / 1;
}
