.imageWithCaption {
  position: relative;
  width: 100%;
  margin-bottom: 80px;

  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  & figcaption {
    position: absolute;
    right: 80px;
    bottom: 80px;
    width: 600px;
    padding: 35px 50px 60px;
    font-family: 'Exo 2', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #21232c;
    letter-spacing: -0.01em;
    background: #9cb7ef;
  }
}
