.buttonSimple {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 35px 2px;
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-size: clamp(0.875rem, 0.77rem + 0.4vw, 1.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  line-height: clamp(0.875rem, 0.77rem + 0.4vw, 1.25rem);
  color: #fff;
  text-decoration: none;
  letter-spacing: -0.03em;
  cursor: pointer;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 40px;
}
