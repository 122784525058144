.input {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  font-family: "Exo 2", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 16px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1.75px;
  border-color: transparent;
  border-radius: 40px;

  &:focus,
  :active {
    outline: 0;
  }

  @media (--wide-mobile) {
    height: 50px;
    padding: 0 20px;
    font-size: 11px;
    line-height: 23px;
    letter-spacing: 1.75px;
    border-radius: 30px;
  }
}

.hf-invalid {
  color: #f8343e;
}
