.productSlider {
  max-width: var(--global-width);
  padding-right: var(--global-padding);
  padding-left: var(--global-padding);
  margin-bottom: 100px;
  overflow: hidden;

  @media (--small-desktop) {
    margin-bottom: 60px;
  }

  @media (--tablet) {
    margin-bottom: 40px;
  }

  @media (--wide-mobile) {
    margin-bottom: 20px;
  }
}

.productSlide {
  position: relative;
  aspect-ratio: 364 / 557;

  @media (--tablet) {
    max-width: 80vw;
  }

  @media (--wide-tablet) {
    width: 40%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
}

.productSlideImage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 6px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.productSlideContent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 40px 30px;

  @media (--wide-mobile) {
    padding: 30px 22px;
  }
}

.productSlideTitle {
  position: relative;
  display: inline-block;
  width: max-content;
  font-family: "Exo 2", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: normal;
  line-height: 58px;
  color: #e6e8eb;
  letter-spacing: -1.3253px;

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    content: "";
    background-color: #e6e8eb;
  }

  @media (--small-desktop) {
    font-size: 32px;
    line-height: 48px;
  }

  @media (--tablet) {
    font-size: 30px;
    line-height: 36px;
  }
}

.productSlideDescription {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #e6e8eb;
  letter-spacing: -0.1px;

  @media (--small-desktop) {
    font-size: 14px;
    line-height: 20px;
  }

  @media (--wide-mobile) {
    font-size: 12px;
    line-height: 19px;
  }
}
