.contactMap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  @media (--wide-tablet) {
    position: relative;
    height: 70vh;
    min-height: 0;
  }
}
