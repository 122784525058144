.cabinet-page {
  & .basic h2 {
    margin-bottom: 50px;

    @media (--wide-mobile) {
      margin-bottom: 30px;
    }
  }

  & .newsListItem {
    @media (--wide-mobile) {
      padding: 25px 20px;
      background: linear-gradient(90deg, #2e313f 0, #2a2d39 0) !important;
    }
  }
}
