/**
 * tingle.js - A simple modal plugin written in pure JavaScript
 * @version v0.16.0
 * @link https://github.com/robinparisi/tingle#readme
 * @license MIT
 */

.tingle-modal * {
  box-sizing: border-box;
}

.tingle-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  cursor:
    url("data:image/svg+xml,%3Csvg width='19' height='19' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.514.535l-6.42 6.42L2.677.536a1.517 1.517 0 00-2.14 0 1.517 1.517 0 000 2.14l6.42 6.419-6.42 6.419a1.517 1.517 0 000 2.14 1.517 1.517 0 002.14 0l6.419-6.42 6.419 6.42a1.517 1.517 0 002.14 0 1.517 1.517 0 000-2.14l-6.42-6.42 6.42-6.418a1.517 1.517 0 000-2.14 1.516 1.516 0 00-2.14 0z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E"),
    auto;
  visibility: hidden;
  background: rgb(0 0 0 / 0.3);
  opacity: 0;
  -webkit-overflow-scrolling: touch;
}

@supports (
  (-webkit-backdrop-filter: blur(12px)) or (backdrop-filter: blur(12px))
) {
  .tingle-modal {
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
}

/* confirm and alerts
-------------------------------------------------------------- */

.tingle-modal--confirm .tingle-modal-box {
  text-align: center;
}

/* modal
-------------------------------------------------------------- */

.tingle-modal--noOverlayClose {
  cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
  display: none;
}

.tingle-modal__close {
  position: fixed;
  top: 2.5rem;
  right: 2.5rem;
  z-index: 1000;
  width: 2rem;
  height: 2rem;
  padding: 0;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.tingle-modal__close svg * {
  fill: currentColor;
}

.tingle-modal__closeLabel {
  display: none;
}

.tingle-modal__close:hover {
  color: #fff;
}

.tingle-modal-box {
  position: relative;
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: auto;
  cursor: auto;
  opacity: 1;
  will-change: transform, opacity;
}

.tingle-modal-box__content {
  padding: 0;
}

.tingle-modal-box__footer {
  width: auto;
  padding: 1.5rem 2rem;
  cursor: auto;
  background-color: #f5f5f5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tingle-modal-box__footer::after {
  display: table;
  clear: both;
  content: "";
}

.tingle-modal-box__footer--sticky {
  position: fixed;
  bottom: -200px; /* TODO : find a better way */
  z-index: 10001;
  opacity: 1;
  transition: bottom 0.3s ease-in-out 0.3s;
}

/* state
-------------------------------------------------------------- */

.tingle-enabled {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
}

.tingle-modal--visible .tingle-modal-box__footer {
  bottom: 0;
}

.tingle-modal--visible {
  visibility: visible;
  opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
  animation: scale 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.tingle-modal--overflow {
  padding-top: 8vh;
  overflow-y: scroll;
}

/* btn
-------------------------------------------------------------- */

.tingle-btn {
  display: inline-block;
  padding: 1rem 2rem;
  margin: 0 0.5rem;
  font-family: inherit;
  font-size: inherit;
  line-height: normal;
  color: #fff;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: grey;
  border: none;
  box-shadow: none;
  transition: background-color 0.4s ease;
}

.tingle-btn--primary {
  background-color: #3498db;
}

.tingle-btn--danger {
  background-color: #e74c3c;
}

.tingle-btn--default {
  background-color: #34495e;
}

.tingle-btn--pull-left {
  float: left;
}

.tingle-btn--pull-right {
  float: right;
}

/* responsive
-------------------------------------------------------------- */

@media (max-width: 540px) {
  .tingle-modal {
    top: 0;
    display: block;
    width: 100%;
    padding-top: 60px;
  }

  .tingle-modal-box {
    width: auto;
    border-radius: 0;
  }

  .tingle-modal-box__content {
    overflow-y: scroll;
  }

  .tingle-modal--noClose {
    top: 0;
  }

  .tingle-modal--noOverlayClose {
    padding-top: 0;
  }

  .tingle-modal-box__footer .tingle-btn {
    display: block;
    float: none;
    width: 100%;
    margin-bottom: 1rem;
  }

  .tingle-modal__close {
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 60px;
    color: #fff;
    background-color: #2c3e50;
    border: none;
    box-shadow: none;
  }

  .tingle-modal__closeLabel {
    display: inline-block;
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif;
    font-size: 1.6rem;
    vertical-align: middle;
  }

  .tingle-modal__closeIcon {
    display: inline-block;
    width: 1.6rem;
    margin-right: 0.8rem;
    font-size: 0;
    vertical-align: middle;
  }
}

/* animations
-------------------------------------------------------------- */

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
