@import "./media.css";
@import "./color.css";

:root {
  --global-percent: 100;
  --global-padding: 7vw;
  --global-width: 1920px;

  /* @media (--desktop) {
    --global-padding: 75px;
  }

  @media (--small-desktop) {
    --global-padding: 30px;
  }

  @media (--wide-mobile) {
    --global-padding: 22px;
  } */
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #f8f8f9;
  background-color: #21232c;
}

html {
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

a[href^="tel:"] {
  appearance: none;
}

.main {
  flex: 1 1 auto;
  width: 100%;
}
