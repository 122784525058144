.button {
  display: inline-flex;
  align-items: center;
  width: max-content;
  height: 55px;
  padding: 0 45px 2px;
  font-family: "Exo 2", sans-serif;
  font-size: 20px;
  font-size: clamp(0.875rem, 0.77rem + 0.4vw, 1.25rem);
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  line-height: clamp(0.875rem, 0.77rem + 0.4vw, 1.25rem);
  color: var(--color);
  text-decoration: none;
  letter-spacing: -0.03em;
  cursor: pointer;
  background: var(--background-color);
  border: none;
  border-radius: 12px;
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff4141;
  }

  /* @media (--small-desktop) {
    font-size: 18px;
  } */
}

.buttonBlue {
  background-color: #85a0d8;

  &:hover {
    background-color: #85a0d8;
  }
}
