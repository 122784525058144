.mainSlide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  padding-top: 120px;
  padding-bottom: 170px;
  overflow: hidden;

  @media (--tablet) {
    height: 100vh;
  }
}

.mainSlideBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mainSlideContent {
  position: relative;
  z-index: 10;

  & h1 {
    margin-bottom: 20px;
    font-family: "Exo 2", sans-serif;
    font-size: 90px;
    font-size: clamp(2.5rem, 1.6409rem + 3.3201vw, 5.625rem);
    font-style: normal;
    font-weight: bold;
    line-height: 90px;
    line-height: clamp(2.5rem, 1.6409rem + 3.3201vw, 5.625rem);
    color: var(--background-color);
    letter-spacing: -0.03em;

    /* @media (--small-desktop) {
      font-size: 65px;
      line-height: 75px;
    } */
  }

  & p {
    max-width: 800px;
    margin-bottom: 32px;
    font-family: "Exo 2", sans-serif;
    font-size: 26px;
    font-size: clamp(0.9375rem, 0.75rem + 0.73vw, 1.625rem);
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    line-height: 145%;
    color: rgb(255 255 255 / 0.97);
    letter-spacing: -0.03em;

    @media (--small-desktop) {
      max-width: 600px;
    }

    @media (--tablet) {
      max-width: 500px;
    }
  }
}

.mainSlideFeatures {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: row;
  gap: 50px;
  gap: clamp(1.875rem, 0.21rem + 3.47vw, 4.375rem);

  @media (--wide-mobile) {
    display: none;
  }
}

.mainSlideFeaturesTopPadding {
  padding-top: 30px;
}

.mainSlideFeature {
  position: relative;
  width: 100%;
  max-width: 320px;
  padding-top: 25px;
  font-family: "Exo 2", sans-serif;
  font-size: 22px;
  font-size: clamp(1.125rem, 0.96rem + 0.35vw, 1.375rem);
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  line-height: clamp(1.625rem, 1.46rem + 0.35vw, 1.875rem);
  color: #fff;
  letter-spacing: -0.03em;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;
    content: "";
    background-color: var(--background-color);

    @media (--small-desktop) {
      height: 8px;
    }
  }

  @media (--small-desktop) {
    margin-right: 26px;

    /* font-size: 18px;
    line-height: 24px; */
  }
}

.mainSlideFeaturesBlue {
  & .mainSlideFeature {
    &::after {
      background-color: #85a0d8;
    }
  }
}

.mainSlideFeatureWithIcon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 320px;
  padding-top: 25px;
  font-family: "Exo 2", sans-serif;
  font-size: clamp(1.125rem, 0.96rem + 0.35vw, 1.375rem);
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  line-height: clamp(1.625rem, 1.46rem + 0.35vw, 1.875rem);
  color: #fff;
  text-align: center;
  letter-spacing: -0.03em;

  @media (--small-desktop) {
    /* font-size: 18px;
    line-height: 24px; */
  }

  & img {
    width: 8vw;
    margin-bottom: 10px;
  }
}
