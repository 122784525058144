.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 850px;
  padding: calc(var(--global-padding) / 3 * 2.5) var(--global-padding);
  background-color: #272a35;
  border-radius: 30px;

  @media (--small-desktop) {
    width: 80vw;
    min-width: 0;
  }

  @media (--wide-mobile) {
    width: calc(100% - var(--global-padding));
    padding: 60px var(--global-padding) 30px;
    margin: 0 auto;
    border-radius: 15px;
  }
}

.modalForm {
  width: 100%;

  & input {
    width: 100%;
  }
}

.modalFormItem {
  position: relative;
  width: 100%;

  & p,
  a {
    font-family: "Exo 2", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: #85a0d8;
    letter-spacing: -0.01em;
  }

  & .hf-warning {
    position: absolute;
    top: calc(100% + 3px);
    left: 25px;
    font-family: "Exo 2", sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.75px;
  }
}

.modalFormItemMiddle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.modalFormClose {
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;

  @media (--wide-mobile) {
    top: 20px;
    right: 20px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }
}

.modalFormRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;

  @media (--wide-mobile) {
    display: flex;
    flex-direction: column;
    gap: 25px;
    justify-content: flex-start;
  }
}

.modalFormTwoColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin-bottom: 30px;

  @media (--wide-mobile) {
    grid-template-columns: 1fr;
    margin-bottom: 25px;
  }
}

.modalFormSubmits {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 30px;

  @media (--wide-mobile) {
    grid-template-columns: 1fr;
  }
}

.modalHeader {
  margin-bottom: 35px;
  font-family: "Exo 2", sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  color: rgb(255 255 255 / 0.97);
  letter-spacing: -0.01em;

  @media (--wide-mobile) {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 28px;
  }
}

.modalFormSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & p {
    margin-bottom: 33px;
    font-family: "Exo 2", sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    color: rgb(255 255 255 / 0.97);
    text-align: center;
    letter-spacing: -0.01em;

    @media (--wide-mobile) {
      margin-bottom: 25px;
      font-size: 22px;
      line-height: 28px;
    }
  }
}
