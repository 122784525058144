@import-glob "layout/layout.css";

@import "swiper/swiper.min.css";

@import-glob "ui/**/*.css";
@import-glob "block/**/*.css";
@import-glob "resize.css";

.dd {
  display: block;
}
