.video {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.videoPoster {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.videoOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 35, 44, 0.79);
}

.videoPlay {
  position: absolute;
  top: 0;
  top: 50%;
  left: 0;
  left: 50%;
  z-index: 30;
  width: 188px;
  height: 188px;
  transform: translate(-50%, -50%);

  & svg {
    width: 188px;
    height: 188px;
  }
}
