.mainSlider {
  height: 100vh;
  min-height: 800px;
  overflow: hidden;

  @media (--tablet) {
    height: 90vh;
    min-height: 0;
  }

  @media (--wide-mobile) {
    height: 100vh;
  }
}

.mainSliderNav {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  transform: translateY(-50%);

  @media (--small-desktop) {
    top: 35%;
  }

  @media (--wide-mobile) {
    top: auto;
    right: auto;
    bottom: calc(30px + env(safe-area-inset-bottom));
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: var(--global-padding);
  }
}

.mainSliderNavItem {
  width: 100%;
  height: 75px;
  padding: 20px 30px 20px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  cursor: pointer;
  border-top: 1px solid #717173;
  transition: all 0.4s ease;

  @media (--small-desktop) {
    width: 150px;
    padding: 20px 20px 20px 0;
    font-size: 12px;
    line-height: 22px;
  }

  @media (--wide-mobile) {
    width: 64px;
    height: 35px;
    margin-right: var(--global-padding);
    font-size: 0;
    border-color: #fff;
    border-top-width: 2px;
  }
}

.mainSliderNavItemActive {
  color: #85a0d8;
  border-top-color: #85a0d8;

  @media (--wide-mobile) {
    border-width: 5px;
    transform: translateY(-10px);
  }
}
